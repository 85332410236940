<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :md="8" :sm="12">
                <el-form-item label="主体名称">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.businessEntityId"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in idBox"
                      :key="item.entityId"
                      :label="item.entityName"
                      :value="item.entityId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="场馆名称">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.venueIds"
                    multiple
                    style="width: 100%"
                    @change="toSelect"
                  >
                    <el-option
                      v-for="item in venueList"
                      :key="item.id"
                      :label="item.venueName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="运动项目">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.sportItemCode"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      :value="item.sportsProjectCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="15">
                <el-form-item label="业务类型">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.orderTypeId"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="订单来源">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.orderFrom"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in DROPDOWNBOX.ORDER_FROM"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="支付方式">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.payCategory"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in paymentMethod"
                      :key="item.code"
                      :label="item.label"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="18">
                <el-form-item label="付款时间">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.range"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button @click="searchDatass" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 50"
        style="width: 100%"
        border
        :span-method="arraySpanMethod"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          min-width="96"
          prop="orderTypeName"
          label="业务"
        ></el-table-column>
        <el-table-column
          align="center"
          min-width="100"
          prop="businessEntityName"
          label="主体名称"
        ></el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="场馆名称"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="sportItemName"
          align="center"
          label="运动项目"
          min-width="110"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="订单数"
        >
          <el-table-column
            align="center"
            prop="payOrderCount"
            min-width="140"
            label="收款笔数"
          >
          <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'PAY')">{{scope.row.payOrderCount}}</div>
            <div v-else>{{scope.row.payOrderCount}}</div>
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="refundOrderCount"
            min-width="140"
            label="退款笔数"
          >
          <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'REFUND')">{{scope.row.refundOrderCount}}</div>
            <div v-else>{{scope.row.refundOrderCount}}</div>
          </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="validOrderCount"
            min-width="140"
            label="有效订单"
          >
          <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'VALID')">{{scope.row.validOrderCount}}</div>
            <div v-else>{{scope.row.validOrderCount}}</div>
          </template>
          </el-table-column> -->
        </el-table-column>
        <el-table-column
          align="center"
          min-width="140"
          label="金额"
        >
          <el-table-column
            align="center"
            min-width="100"
            prop="payAmount"
            show-overflow-tooltip
            label="收款金额"
          >
          <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'PAY')">{{scope.row.payAmount.toFixed(2)}}</div>
            <div v-else>{{scope.row.payAmount.toFixed(2)}}</div>
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="100"
            prop="refundAmount"
            show-overflow-tooltip
            label="退款金额"
          >
          <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'REFUND')">{{scope.row.refundAmount.toFixed(2)}}</div>
            <div v-else>{{scope.row.refundAmount.toFixed(2)}}</div>
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="100"
            prop="validAmount"
            show-overflow-tooltip
            label="实际收入"
          >
          <!-- <template slot-scope="scope">
            <div class="table-cell" v-if="scope.row.venueId" @click="toDetail(scope.row, 'VALID')">{{scope.row.validAmount.toFixed(2)}}</div>
            <div v-else>{{scope.row.validAmount.toFixed(2)}}</div>
          </template> -->
          </el-table-column>
        </el-table-column>
        <el-table-column
          align="center"
          prop="payProportion"
          min-width="120"
          label="收入占比"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="refundProportion"
          min-width="120"
          label="退款占比"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="page-wrapper">
      <el-button class="default-button" :disabled="exportDisabled" @click="exportData">导出</el-button>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination> -->
    </div>
    <!-- 添加/修改弹窗  -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" @closeDialog="closeDialog"></add-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import download from "@/mixins/download";
import IndexClass from "./indexClass";
import addDialog from './dialog/addDialog.vue'

export default {
  mixins: [mixin, download],
  components: { addDialog },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      addDialog: {
        visible: false,
      },
      payValue: '',
      tableData: [],
      typeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "预约",
          value: "1",
        },
        {
          label: "购票",
          value: "2",
        },
        {
          label: "课程",
          value: "3",
        },
        {
          label: "赛事",
          value: "4",
        },
        {
          label: "会员卡",
          value: "6",
        },
        {
          label: "会员卡-充值",
          value: "8",
        },
        {
          label: "商品",
          value: "9",
        },
      ],
      sportList: [],
      idBox: [], // 主体ID下拉框
      paymentMethod: [],
      queryParams: {},
      pickerOptions: {
        disabledDate(date) {
          const zero = new Date().setHours(0, 0, 0, 0);
          if (date.getTime() > zero - 1) {
            return true;
          }
          return false;
        },
      },
      exportDisabled: false, // 导出禁用
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN", "venueId", "venueList", "merchantAccountDetailInfoDTO"]),
  },
  filters: {},
  activated() {
    this.getMerchantInfo();
    this.getPaymentMethods();
    this.searchDatass()
  },
  methods: {
    toSelect(value) {
      if (value && value.length > 0) {
        this.getSportList(value);
      } else {
        this.form.sportItemCode = "";
        this.sportList = [];
      }
    },
    getSportList(id) {
      this.$http
        .post(apis.operationGetSportsProjectListByIds, { venueIds: id })
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    getPaymentMethods() {
      this.$http.get(apis.getPaymentMethods).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          this.paymentMethod = res.data.data;
          console.log(this.paymentMethod);
          // console.log(Object.keys(res.data.data));
          // console.log(Object.values(res.data.data));
        }
      });
      console.log(this.paymentMethod);
    },
    // 查看弹窗
    toDetail(row, queryType) {
      const formData = { ...this.queryParams, ...row, ...{ 'queryType': queryType } }
      console.log(formData)
      console.log(this.$refs['addDialog'])
      this.$refs['addDialog'].getDataQuery(formData)
      this.$nextTick(() => {
        this.addDialog.visible = true
      })
    },
    // 关闭弹窗
    closeDialog() {
      this.addDialog.visible = false
    },
    // 主体id下拉框
    getMerchantInfo() {
      this.$http.get(apis.getMerchantInfo).then((res) => {
        if (res.data.code === 0) {
          this.idBox = res.data.data.entityBaseInfoList
        }
      });
    },
    // 表格行合并
    arraySpanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === this.tableData.length - 1) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3,
          };
        }
        if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
        if (columnIndex === 2) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    /**
     * @function 搜索方法
     */
    searchDatass() {
      const formData = {
        ...this.form,
      };
      console.log(formData);
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.dateFrom	 = this.form.range[0];
          formData.dateEnd = this.form.range[1];
        }
      } else {
        formData.dateFrom	 = "";
        formData.dateEnd = "";
      }
      formData.merchantId = this.merchantAccountDetailInfoDTO.merchantId
      // formData.merchantId = '301c4c74d6f14b638a57dbd2d8d96ebb'
      // formData.businessEntityId = '164865843b2947c29f75487b73dc1bd5'
      this.$http
        .post(apis.queryMerchantFinancialStatement, {
          ...formData,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.queryParams = res.data.rows.queryParams
            this.tableData = res.data.rows.rowDataList
            const data = res.data.rows.statisticsData
            data.payOrderCount = data.allPayOrderCount
            data.payAmount = data.allPayAmount
            data.payProportion = data.allPayProportion
            data.refundAmount = data.allRefundAmount
            data.refundOrderCount = data.allRefundOrderCount
            data.refundProportion = data.allRefundProportion
            data.validAmount = data.allValidAmount
            data.orderTypeName = '合计'
            this.tableData.push(data)
            console.log(data.allRefundProportion);
          }
          console.log(res.data);
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new IndexClass("form");
      this.sportList = []
      this.$nextTick(() => {
        this.searchDatass();
      });
    },
    // 导出
    exportData() {
      this.exportDisabled = true
      const formData = {
        ...this.form,
      };
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.dateFrom	 = this.form.range[0];
          formData.dateEnd = this.form.range[1];
        }
      } else {
        formData.dateFrom	 = "";
        formData.dateEnd = "";
      }
      formData.merchantId = this.merchantAccountDetailInfoDTO.merchantId
      this.$http.post(apis.orderReportexport, formData).then((res) => {
        if (res) {
          this.exportDisabled = false
        }
        if (res.data.code === 0) {
          this.resolveUrl(res.data.rows)
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0 20px;
}
.table-container {
  margin-top: 0;
}
.table-cell {
  color: blue;
  cursor: pointer;
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
</style>
