/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.dateEnd = ''
      this.dateFrom = ''
      this.orderFrom = ''
      this.orderTypeId = ''
      this.sportItemCode = ''
      this.venueIds = []
      this.range = []
      this.businessEntityId = ''
      this.payCategory = ''
    }
  }
}
export default searchFrom
